import {graphql, useStaticQuery} from "gatsby";
import React from 'react'
import Layout from '/src/components/layout/en/Layout.js'

import DescriptionBusiness from "/src/components/description-business/DescriptionBusiness";
// import bg from "../../../static/images/hero/image/business-portfolio.jpg";
import HeroSmall from "../../components/hero-small/HeroSmall.js";
import Seo from "../../components/seo";
import BusinessPortfolioList from "../../components/business-portfolio-list/BusinessPortfolioList"

const BusinessPortfolio = (props) => {
    
    const {strapiBusinessPortfolio, allStrapiBusinessPortfolioLists} = useStaticQuery(query);
    
    return (
        <Layout path={props.location.pathname}>
            <Seo
              title={strapiBusinessPortfolio.metaTitle}
              description={strapiBusinessPortfolio.metaDescription}
              keywords={strapiBusinessPortfolio.metaKeywords} />
            <HeroSmall img={`${process.env.GATSBY_STRAPI_API_URL}${strapiBusinessPortfolio.heroBg?.url}`} strapiHomePage={strapiBusinessPortfolio} />
            <DescriptionBusiness data={strapiBusinessPortfolio}/>
            <BusinessPortfolioList datas={allStrapiBusinessPortfolioLists} />
        </Layout>
    )
}

export default BusinessPortfolio

const query = graphql`
    query {
        strapiBusinessPortfolio(locale: {eq: "en"}) {
            metaTitle
            metaDescription
            metaKeywords
            title
            subtitle
            statement
            statementDescription
            statementDescription2
            statementList
            heroBg {
              url
            }
            breadcrumb {
                label
                url
            }
        }
        allStrapiBusinessPortfolioLists(filter: {locale: {eq: "en"}}, sort: {fields: position}) {
            edges {
                node {
                    descriptions
                    img {
                        url
                    }
                    subtitle
                    title
                    link
                    logo {
                        url
                    }
                }
            }
        }
    }
`;

