import React, {useEffect} from 'react'
import styled from 'styled-components'
import Modal from 'react-modal'
import buttonImgBlack from '/static/images/modal-leader/close-black.svg'
import buttonImgWhite from '/static/images/modal-leader/close-white.svg'

import H4 from '/src/components/library/H4'
import Body from '/src/components/library/Body'

let customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#153A62B3',
        zIndex: 20,
    },
    content: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '0px',
        backgroundColor: 'white',
    }
};

Modal.setAppElement('body')

const ModalBusiness = ({data, modalState, setModalState}) => {
    // const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        // const handleResize = () => setWidth(window.innerWidth)
        document.body.style.height = "100%";
        document.body.style.overflowY = "hidden";
        // window.addEventListener("resize", handleResize)
        // if (width < 600)
        //     customStyles['content']['height'] = '80%';
        // else
        //     customStyles['content']['height'] = 'unset';
        // return () => {
        //     window.removeEventListener("resize", handleResize)
        // }
    });

    function closeModal() {
        setModalState(false);
        document.body.style.height = "unset";
        document.body.style.overflowY = "unset";
    }

    return (
        <ModalSafeRecreationStyle>
            <Modal
                isOpen={modalState}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel={data.name}
                shouldCloseOnOverlayClick={true}
                className="modal-business"
            >
                <CloseModal onClick={() => closeModal()}>
                    <ButtonImage/>
                </CloseModal>
                <Wrapper>
                    <Info>
                        {data.logo?.url &&
                            <Image img={`${process.env.GATSBY_STRAPI_API_URL}${data.logo?.url}`}/>
                        }
                        <TextWrapper>
                            <H4>{data.title}</H4>
                            <Body><Span>{data.subtitle}</Span></Body>
                            <Body><a href={`https://${data.link}`} target={'_blank'} rel={"noreferrer"}><SpanLink>{data.link}</SpanLink></a></Body>
                            <StatusWrapper>
                                {/*<Body>{data.position}</Body>*/}
                            </StatusWrapper>
                            <Distance>
                                {
                                    data.descriptions?.map((p) => {
                                        let label = "";
                                        let text = p;
                                        let value = "";
                                        if (p[0] === "[") {
                                            label = p.substring(0, p.indexOf(" "));
                                            text = p.substring(p.indexOf(" "), p.length);
                                            console.log("TEXT", text);
                                            console.log("p", p);
                                            const index = text.indexOf(": ");
                                            if (label === "[nomargin]" && index !== -1) {
                                                value = text.substring(text.indexOf(": ") + 2, text.length)
                                                text = text.substring(0, text.indexOf(": "))
                                            }
                                        }
                                        return (
                                          <DescriptionWrapper className={label === "[nomargin]" ? "no-margin" : ""}>
                                              {label === "[title]" ?
                                                <Title>
                                                    <H4>{text}</H4>
                                                </Title> : text === " Phone" || text === " Email" || text === " Fax"?
                                                  <Body className={"no-margin"}>
                                                      {console.log(`a${text}b`)}
                                                      {text}: { text === " Phone" || text === " Fax" ? <a href={`tel:${value}`}>{value}</a> :
                                                    <a href={`mailto:${value}`}>{value}</a>}
                                                  </Body> :
                                                <Body className={"no-margin"}>
                                                    {text}
                                                </Body>
                                              }
                                          </DescriptionWrapper>
                                        )
                                    })
                                }
                            </Distance>
                        </TextWrapper>
                    </Info>
                </Wrapper>
            </Modal>
        </ModalSafeRecreationStyle>
    )
}

export default ModalBusiness

const Span = styled.div`
  opacity: 0.4;
`

const Title = styled.div`
  margin-top: 3rem;
  padding-top: 3rem;
  border-top: 1px solid #DCDDDE;
`

const SpanLink = styled.div`
  text-decoration: underline;
  color: #1A2353;
`

const ModalSafeRecreationStyle = styled.div`
  position: relative;
  //-ms-overflow-style: none;
  //&::-webkit-scrollbar {
  //  width: 0;
  //  background: transparent;
  //}

  @media ${({theme}) => theme.breakpoints.mobile} {
    overflow-y: auto;
  }
`

const CloseModal = styled.button`
  cursor: pointer;
  z-index: 5;
  border: none;
  position: absolute;
  top: 4rem;
  right: 4rem;
  background: none;
  @media ${({theme}) => theme.breakpoints.mobile} {
    top: 1.4rem;
    right: 1.4rem;
  }
`

const ButtonImage = styled.div`
  background-image: url(${buttonImgBlack});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 2.4rem;
  height: 2.4rem;
    // @media ${({theme}) => theme.breakpoints.mobile} {
    //   background-image: url(${buttonImgWhite});
  // }
`

const Wrapper = styled.div`
  position: relative;
  height: 90%;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;

  @media ${({theme}) => theme.breakpoints.mobile} {
    flex-direction: column;
    //height: 100vh;
    overflow-y: scroll;
  }
  //-ms-overflow-style: none;

  //&::-webkit-scrollbar {
  //  width: 0;
  //  background: transparent;
  //}
`

const Image = styled.div`
  // flex: .7;
  background-image: url(${({img}) => img});
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 100px;
  height: 60px;
  margin-bottom: 2rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    // flex: unset;
    // min-height: 40vh;
  }
`

const Info = styled.div`
  margin-top: 5rem;
  margin-left: 5rem;
  flex: 1;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 5rem;
    margin-left: 2.4rem;
    margin-right: 2.4rem;
    flex: unset;
  }
`

const TextWrapper = styled.div`
  // height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 97%;
  // padding-bottom: 1rem;
  //  max-width: 60rem;
  // padding-right: 10rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding-bottom: 6rem;
    padding-right: 0;
  }
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  p {
    margin: 1rem 0 0 0;
  }
`

const Distance = styled.div`
  margin-top: .8rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-top: 0;
  }
  .no-margin {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`

const StatusWrapper = styled.div`
  margin-top: 1.4rem;
`

const DescriptionWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 12rem;
  @media ${({theme}) => theme.breakpoints.mobile} {
    margin-right: 0;
  }
`
