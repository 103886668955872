import React, {useState} from 'react'
import styled from 'styled-components'
import Caption from "../library/Caption.js"
import H2 from "../library/H2.js"
import Body from "../library/Body.js"
import ModalBusiness from "../modal-business/ModalBusiness.js";

const BusinessPortfolioList = ({datas}) => {
    const [modalState, setModalState] = useState(false)
    const [elem, setElem] = useState(null)


    return (
        <BusinessPortfolioListStyle>
            {modalState &&
                <ModalBusiness data={elem} modalState={modalState} setModalState={setModalState}/>
            }
            <BusinessPortfolioMobile>
                {datas.edges.map((data, key) => {
                    if (data.node.img) {
                        return (
                            <BusinessPortfolioWrapper key={key} img={`${process.env.GATSBY_STRAPI_API_URL}${data.node.img?.url}`}
                                                      onClick={() => {
                                                          setElem(data.node)
                                                          setModalState(true)
                                                      }}>
                                <Overlay>
                                    <Text>
                                        <Caption>{data.node.subtitle}</Caption>
                                        <Wrapper>
                                            <H2>{data.node.title}</H2>
                                            <Body><a href={`https://${data.node.link}`}
                                                     target={'_blank'} rel={'noreferrer'}>{data.node.link}</a></Body>
                                        </Wrapper>
                                    </Text>
                                </Overlay>
                            </BusinessPortfolioWrapper>)
                    } else
                        return (null)
                })}
            </BusinessPortfolioMobile>
            <BusinessPortfolioDesktop>
                <WrapperPortfolio>
                {datas.edges.map((data, key) => {
                    if (data.node.img) {
                        return (
                            <BusinessPortfolioWrapper key={key} img={`${process.env.GATSBY_STRAPI_API_URL}${data.node.img?.url}`}
                                                      onClick={() => {
                                                          setElem(data.node)
                                                          setModalState(true)
                                                      }}>
                                <Overlay>
                                    <Text>
                                        <Caption>{data.node.subtitle}</Caption>
                                        <Wrapper>
                                            <H2>{data.node.title}</H2>
                                            <Body><a href={`https://${data.node.link}`}
                                                     target={'_blank'} rel={'noreferrer'}>{data.node.link}</a></Body>
                                        </Wrapper>
                                    </Text>
                                </Overlay>
                            </BusinessPortfolioWrapper>)
                    } else
                        return (null)
                })}
                </WrapperPortfolio>
            </BusinessPortfolioDesktop>
        </BusinessPortfolioListStyle>
    )
}

export default BusinessPortfolioList

const BusinessPortfolioListStyle = styled.div`
  padding-bottom: 6rem;
`

const WrapperPortfolio = styled.div`
    display: grid;
gap: 1.5rem;
grid-template-columns: 1fr 1fr;
width: 100%;
    max-width: ${({ theme }) => theme.layout.xxxLargeScreen};
    @media ${({theme}) => theme.breakpoints.tablets_reverse} {
      display: none;
    }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    text-decoration: underline;
  }

  a {
    color: white;
  }

  gap: 2rem;
`
const Text = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    padding: 3rem;
    height: 80%;
    justify-content: space-between;
    width: 100%;
    max-width: calc(${({ theme }) => theme.layout.xxLargeScreen} / 2);
    @media ${({theme}) => theme.breakpoints.desktops_news} {
        padding: 2.4rem;
      max-width: none;
    }
    @media ${({theme}) => theme.breakpoints.tablets_reverse} {
      height: 100%;
      padding: 2.4rem;
            max-width: none;
    }
`
const BusinessPortfolioWrapper = styled.div`
    cursor: pointer;
    background-image: url(${(props) => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  position: relative;
`

const BusinessPortfolioMobile = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media ${({theme}) => theme.breakpoints.tablets} {
    display: none;
  }
`

const Overlay = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: inherit;
  height: inherit;
  &:hover {
    background: #081E44B3;
    transition: background .3s cubic-bezier(.27,.4,.68,1.16);
  }
  &:not(:hover) {
    background: transparent;
    transition: background .3s cubic-bezier(.27,.4,.68,1.16);
  }
`

const BusinessPortfolioDesktop = styled.div`
    display: flex;
    justify-content: center;
`
