import React from 'react'
import styled from 'styled-components'

import H2 from '/src/components/library/H2'
import Body from '/src/components/library/Body'

const DescriptionBusiness = ({data}) => {
    return (
        <StyledDescription>
            <Wrapper>
                <TitleWrapper>
                    <SizeWrapper>
                        <H2>{data.statement}</H2>
                    </SizeWrapper>
                </TitleWrapper>
                <BodyWrapper>
                    <Body>{data.statementDescription}</Body>
                    <ul>
                        {
                            data.statementList.map((description, key) => {
                                return (
                                    <li key={key}><Body>{description}</Body></li>
                                )
                            })
                        }
                    </ul>
                    <Body>{data.statementDescription2}</Body>
                </BodyWrapper>
            </Wrapper>
        </StyledDescription>
    )
}

export default DescriptionBusiness


const Wrapper = styled.div`
  max-width: ${({theme}) => theme.layout.xxLargeScreen};
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  padding: 10rem 2.4rem;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    // padding: 6rem 2.4rem;
    flex-direction: column;
    gap: 1.4rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    padding: 2.6rem 2.4rem;
  }
`

const StyledDescription = styled.div`
  // padding: 12rem 12rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    flex-direction: column;
    gap: 1.4rem;
    // padding: 12rem 3.2rem;
  }
  @media ${({theme}) => theme.breakpoints.mobile} {
    // padding: 2.6rem 1.6rem;
  }
`

const TitleWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-start;
`

const SizeWrapper = styled.div`
  width: 80%;
  @media ${({theme}) => theme.breakpoints.tablets_reverse} {
    width: 100%;
  }
`

const BodyWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;

  ul {
    margin: 2.5rem 0 2.5rem 3.5rem;
  }
`
